/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { Empty, Image, Spin } from "antd";
import { AlertMessage } from "../../global/AlertMessage";
import SearchInput from "../../global/SearchInput";
import { DeleteModalProduct } from "../../global/DeleteModalProduct";

type Props = {
  className: string;
};

interface Shop {
  id: number;
  photo: string;
  name: string;
  phone: string;
  address: string;
}

interface Product {
  id: number;
  photo: string;
  name: string;
  price: string;
  stock: number;
  sold: number;
  is_active: boolean;
  shop: Shop;
}

const AllProducts: React.FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [allProductData, setAllProductData] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [zeroData, setZeroData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [startIndex, setStartIndex] = useState(0);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  useEffect(() => {
    if (token) {
      getAllProduct();
    }
  }, [token, meta.page, meta.per_page, searchQuery]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getAllProduct = async () => {
    setLoading(true);
    const resAllProduct = await API.GetAllProduct(
      token,
      searchQuery,
      meta.page,
      meta.per_page
    );
    if (resAllProduct.status == 200 && resAllProduct.data.data.length > 0) {
      setAllProductData(resAllProduct.data.data);
      setMetaData(resAllProduct.data.meta);
    } else {
      setAllProductData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resAllProduct.data.message);
    }
    setLoading(false);
  };

  const handleDeleteProduct = async (ids: number[]) => {
    const formData = new FormData();

    ids.forEach((id, index) => {
      formData.append(`id[${index}]`, id.toString());
    });

    const res = await API.DeleteProduct(currentUser?.token, formData);

    if (res.status === 200) {
      await getAllProduct();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
      setSelectedItems([]);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.data.message);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  const formatToRupiah = (price: number) => {
    const formatted = `Rp ${Number(price).toLocaleString("id-ID")}`;
    return formatted;
  };

  const formatPrice = (price: string | null) => {
    if (!price) {
      return "";
    }
    const [min, max] = price.split(" - ");
    const formattedMin = `Rp ${Number(min).toLocaleString("id-ID")}`;
    const formattedMax = `Rp ${Number(max).toLocaleString("id-ID")}`;
    return `${formattedMin} - ${formattedMax}`;
  };

  const handleExport = async () => {
    setLoading(true);
    const res = await API.GetExportProduct(currentUser?.token);
    if (res.status === 200) {
      await getAllProduct();
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `products.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSuccessMessage("File berhasil diunduh");
      setShowAlert(true);
    } else {
      setShowAlertError(true);
      setErrorMessage("Gagal mengekspor produk");
    }
    setLoading(false);
  };

  const handleToggleActive = async (id: number) => {
    const formData = new FormData();
    formData.append("id[]", id.toString());

    const response = await API.UpdateStatusProduct(
      currentUser?.token,
      formData
    );

    if (response.status === 200) {
      setAllProductData((prevAllProductData) => {
        return prevAllProductData.map((product) => {
          if (product.id === id) {
            return { ...product, is_active: !product.is_active };
          }
          return product;
        });
      });
      setShowAlert(true);
      setSuccessMessage(response.data.message);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(response.data.message);
    }

    setLoading(false);
  };

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelectAll = (checked: boolean) => {
    setSelectedItems(
      checked ? allProductData.map((product) => product.id) : []
    );
  };

  const handleSelectRow = (id: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };

  return (
    <div className={`card ${className}`}>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Produk</h3>
        </div>
      </div>
      <div className="separator separator"></div>

      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-sm  btn-light-primary"
                onClick={handleExport}
                disabled={loading}
              >
                <KTSVG
                  path="/media/icons/duotune/files/fil021.svg"
                  className="svg-icon-2"
                />
                Ekspor Produk
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center position-relative">
            {selectedItems.length > 1 && (
              <button
                type="button"
                className="btn btn-icon btn-light-danger me-4"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_delete_selected"
              >
                <div className="btn btn-sm ">
                  <i className="ki-duotone ki-cross-square fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </button>
            )}
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="w-10px ps-2 rounded-start">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="select-all"
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  </div>
                </th>
                <th className="min-w-125px">Produk</th>
                <th className="min-w-125px">Harga</th>
                <th className="min-w-125px">Nama Toko</th>
                <th className="min-w-100x">Stok</th>
                <th className="min-w-100px">Terjual</th>
                <th className="min-w-100px">Status</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    <Spin size="default" />
                  </td>
                </tr>
              ) : allProductData.length > 0 ? (
                allProductData.map((product, index) => (
                  <tr key={product.id}>
                    <td className="text-center">
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={selectedItems.includes(product.id)}
                          onChange={() => handleSelectRow(product.id)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-60px me-5">
                          <span className="symbol-label bg-light">
                            <Image
                              src={product.photo}
                              className="rounded"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-dark fw-bold mb-1 fs-6">
                            {product.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.price && typeof product.price === "number"
                          ? formatToRupiah(product.price)
                          : formatPrice(product.price)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.shop.name}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.stock}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted text-center fw-bold d-block mb-1 fs-6">
                        {product.sold}
                      </span>
                    </td>
                    <td>
                      <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                        <input
                          className="form-check-input h-30px w-60px"
                          type="checkbox"
                          checked={product.is_active}
                          onChange={() => handleToggleActive(product.id)}
                          id={`flexSwitch${product.id}`}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                        <Link
                          to="/marketplace/semuaproduk/edit-produk"
                          state={{ id: product.id }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </Link>
                        <button
                          className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2 mb-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_delete_${product.id}`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button>
                      </div>
                      <DeleteModalProduct
                        modalId={`kt_modal_delete_${product.id}`}
                        ids={[product.id]}
                        names={[product.name]}
                        onDelete={(ids) => handleDeleteProduct(ids)}
                      />
                      {selectedItems.length > 1 && (
                        <DeleteModalProduct
                          modalId="kt_modal_delete_selected"
                          ids={selectedItems}
                          names={allProductData
                            .filter((product) =>
                              selectedItems.includes(product.id)
                            )
                            .map((product) => product.name)}
                          onDelete={(ids) => handleDeleteProduct(ids)}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    {zeroData ? (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalRecords={meta.total}
            recordsPerPage={meta.per_page}
            currentPage={meta.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export { AllProducts };
