/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";

const passwordFormValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  password_confirmation: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const UpdatePassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showPasswordForm, setPasswordForm] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { currentUser } = useAuth();
  const token = currentUser?.token;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("old_password", values.old_password);
      formData.append("password", values.password);
      formData.append("password_confirmation", values.password_confirmation);

      const res = await API.UpdatePassword(formData, token);
      if (res.status === 200) {
        setSuccessMessage(res.data.message);
        setShowAlert(true);
        setPasswordForm(false);
        setLoading(false);
      } else {
        setErrorMessage(res.message);
      }

      setLoading(false);
    },
  });

  return (
    <>
      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_signin_method"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Ubah Password</h3>
          </div>
        </div>

        <div id="kt_account_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="d-flex flex-wrap align-items-center mb-10">
              <div
                id="kt_signin_password"
                className={" " + (showPasswordForm && "d-none")}
              >
                <div className="fs-6 fw-bolder mb-1">Password</div>
                <div className="fw-bold text-gray-600">********</div>
              </div>

              <div
                id="kt_signin_password_edit"
                className={"flex-row-fluid " + (!showPasswordForm && "d-none")}
              >
                <form
                  onSubmit={formik.handleSubmit}
                  id="kt_signin_change_password"
                  className="form"
                  noValidate
                >
                  <div className="row mb-1">
                    <div className="col-lg-4">
                      <div className="fv-row mb-0">
                        <label
                          htmlFor="old_password"
                          className="form-label fs-6 fw-bolder mb-3"
                        >
                          Password Lama
                        </label>
                        <input
                          type="password"
                          placeholder="Password lama"
                          className="form-control form-control-lg form-control-solid "
                          id="old_password"
                          {...formik.getFieldProps("old_password")}
                        />
                        {formik.touched.old_password &&
                          formik.errors.old_password && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.old_password}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="fv-row mb-0">
                        <label
                          htmlFor="password"
                          className="form-label fs-6 fw-bolder mb-3"
                        >
                          Password Baru
                        </label>
                        <input
                          type="password"
                          placeholder="Password baru"
                          className="form-control form-control-lg form-control-solid "
                          id="password"
                          {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.password}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="fv-row mb-0">
                        <label
                          htmlFor="password_confirmation"
                          className="form-label fs-6 fw-bolder mb-3"
                        >
                          Konfirmasi Password Baru
                        </label>
                        <input
                          type="password"
                          placeholder="Konfirmasi password baru"
                          className="form-control form-control-lg form-control-solid "
                          id="password_confirmation"
                          {...formik.getFieldProps("password_confirmation")}
                        />
                        {formik.touched.password_confirmation &&
                          formik.errors.password_confirmation && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.password_confirmation}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="form-text mb-5">
                    Password harus minimal 8 karakter
                  </div>

                  <div className="d-flex">
                    <button
                      id="kt_password_submit"
                      type="submit"
                      className="btn btn-primary me-2 px-6"
                    >
                      {!loading && "Simpan"}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Sabar boss...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setPasswordForm(false);
                      }}
                      id="kt_password_cancel"
                      type="button"
                      className="btn btn-color-gray-400 btn-active-light-primary px-6"
                    >
                      Batal
                    </button>
                  </div>
                </form>
              </div>

              <div
                id="kt_signin_password_button"
                className={"ms-auto " + (showPasswordForm && "d-none")}
              >
                <button
                  onClick={() => {
                    setPasswordForm(true);
                  }}
                  className="btn btn-light btn-active-light-primary"
                >
                  Ubah Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { UpdatePassword };
