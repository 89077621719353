import React, { useEffect } from "react";
import { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";

type Transaction = {
  id: number;
  api_id: number | null;
  name: string;
  bank_account: string;
  account_holder: string;
  nominal: number;
  fee: number;
  total: number;
  status: string;
  description: string | null;
  date: string;
  update: string;
};

interface DetailTransactionUserProps {
  isOpen: boolean;
  onClose: () => void;
  id: number | null;
  whitdraw: Transaction;
}

const DetailWhitdrawModal: FC<DetailTransactionUserProps> = ({
  isOpen,
  onClose,
  id,
  whitdraw,
}) => {
  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="modal fade show d-block"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content p-4 pt-0 pb-0"
                style={{ borderRadius: "20px" }}
              >
                <div className="modal-header">
                  <h2 className="fw-bold">Detail Penarikan</h2>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card border-0 mb-6">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div
                          className="bg-light rounded-circle d-flex align-items-center justify-content-center"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/finance/fin008.svg"
                            className="svg-icon-2hx svg-icon-primary"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div>
                          <strong>
                            {whitdraw.api_id !== null
                              ? whitdraw.api_id
                              : "ID Penarikan"}
                            <span className="text-primary"></span>
                          </strong>
                        </div>
                        <div className="text-muted">
                          <span>{whitdraw.date}</span>
                        </div>
                      </div>
                      <div className="text-end">
                        <div className="fs-5">
                          <div>
                            <span
                              className={`float-end badge ${
                                whitdraw.status === "pending"
                                  ? "badge-light-warning"
                                  : whitdraw.status === "approved"
                                  ? "badge-light-warning"
                                  : whitdraw.status === "rejected"
                                  ? "badge-light-danger"
                                  : whitdraw.status === "cancelled"
                                  ? "badge-light-danger"
                                  : ""
                              }`}
                            >
                              {whitdraw.status === "pending"
                                ? "Pending"
                                : whitdraw.status === "approved"
                                ? "Diterima"
                                : whitdraw.status === "rejected"
                                ? "Ditolak"
                                : whitdraw.status === "cancelled"
                                ? "Dibatalkan"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pengiriman */}
                  <div className="separator separator-dashed mt-6 mb-6"></div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Nama</span>
                    <span className="float-end">{whitdraw.name}</span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Tujuan</span>
                    <span className="float-end">{whitdraw.bank_account}</span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Atas Nama</span>
                    <span className="float-end">{whitdraw.account_holder}</span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Update</span>
                    <span className="float-end">
                      {formatDate(whitdraw.update)}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Deskripsi</span>
                    <span className="float-end">{whitdraw.description}</span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Nominal</span>
                    <span className="float-end">
                      {whitdraw.nominal.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">
                      Biaya Penanganan
                    </span>
                    <span className="float-end">
                      {whitdraw.fee.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="text-gray-400 fw-semibold">Total</span>
                    <span className="float-end">
                      {whitdraw.total.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>

                {/* End Detail Pembayaran */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export { DetailWhitdrawModal };
