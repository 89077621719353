// Shop.tsx

import React, { useEffect, useState } from "react";
import {
  KTCardBody,
  KTSVG,
  toAbsoluteUrl,
} from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { FormatDesc } from "../../global/FormatDescription";
import SearchInput from "../../global/SearchInput";
import { DeleteModal } from "../../global/DeleteModal";
import { Empty, Image, Spin } from "antd";

type Props = {
  className: string;
};

type ShopData = {
  id: number;
  photo: string;
  name: string;
  phone: string;
  owner: string;
  address: string;
  province: {
    id: number;
    name: string;
  };
  city: {
    id: number;
    name: string;
  };
  district: {
    id: number;
    name: string;
  };
  subdistrict: {
    id: number;
    name: string;
  };
  zip_code: number;
  address_detail: string;
  lat: string;
  lng: string;
  description: string;
  is_both: boolean;
  is_free_shipping: boolean;
  is_active: boolean;
  is_open: boolean;
  is_verified: boolean;
};

const Shop: React.FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [shopData, setShopData] = useState<ShopData[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [startIndex, setStartIndex] = useState(0);
  const [zeroData, setZeroData] = useState("");

  useEffect(() => {
    if (token) {
      getShop();
    }
  }, [token, meta.page, meta.per_page, searchQuery]);

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getShop = async () => {
    setLoading(true);
    const resShopData = await API.GetShop(
      token,
      meta.page,
      searchQuery,
      meta.per_page
    );

    if (resShopData.status === 200 && resShopData.data.data.length > 0) {
      setShopData(resShopData.data.data);
      setMetaData(resShopData.data.meta);
      setZeroData("");
    } else {
      setShopData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resShopData.data.message);
    }
    setLoading(false);
  };

  const handleDeleteShop = async (id: number) => {
    const resDeleteShop = await API.DeleteShop(token, id);
    if (resDeleteShop.status === 200) {
      await getShop();
      setSuccessMessage(resDeleteShop.data.message);
      setShowAlert(true);
    } else {
      setErrorMessage(resDeleteShop.message);
      setShowAlertError(true);
    }
  };

  const handleToggleActive = async (id: number) => {
    const resUpdateStatusShop = await API.UpdateStatusShop(token, id);
    if (resUpdateStatusShop.status === 200) {
      setShopData((prevShopData) =>
        prevShopData.map((shopItem) =>
          shopItem.id === id
            ? { ...shopItem, is_active: !shopItem.is_active }
            : shopItem
        )
      );
      setSuccessMessage(resUpdateStatusShop.data.message);
      setShowAlert(true);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateStatusShop.message);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({ ...prevMetaData, page: pageNumber }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  const handleExport = async () => {
    setLoading(true);
    const res = await API.GetExportShop(currentUser?.token);
    if (res.status === 200) {
      await getShop();
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `shops.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSuccessMessage("File berhasil diunduh");
      setShowAlert(true);
    } else {
      setShowAlertError(true);
      setErrorMessage("Gagal mengekspor toko");
    }
    setLoading(false);
  };

  return (
    <div className={`card ${className}`}>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Daftar Toko</h3>
        </div>
      </div>
      <div className="separator separator"></div>

      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={handleExport}
                disabled={loading}
              >
                <KTSVG
                  path="/media/icons/duotune/files/fil021.svg"
                  className="svg-icon-2"
                />
                Ekspor Toko
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center position-relative">
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
        </div>
      </div>

      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-60px rounded-start">No</th>
                <th className="min-w-250px">Nama</th>
                <th className="min-w-125px">Nomor Telepon</th>
                <th className="min-w-125px">Pemilik</th>
                <th className="min-w-125px">Deskripsi</th>
                <th className="min-w-125px">Alamat</th>
                <th className="min-w-125px">Status</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    <Spin size="default" />
                  </td>
                </tr>
              ) : shopData.length > 0 ? (
                shopData.map((shop, index) => (
                  <tr key={shop.id}>
                    <td className="text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center flex-column">
                          <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                            {startIndex + index + 1}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-60px me-5">
                          <span className="symbol-label bg-light">
                            <Image
                              src={shop.photo}
                              className="rounded"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-dark fw-bold fs-6 me-2">
                            {shop.name}
                            {shop.is_verified && (
                              <img
                                alt="Verified"
                                src={toAbsoluteUrl(
                                  "/media/logos/ic-verived.svg"
                                )}
                                className="h-14px app-sidebar-logo-default ms-2"
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-muted fw-bold mb-1 fs-6">
                            {shop.phone}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-muted fw-bold mb-1 fs-6">
                            {shop.owner}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-start flex-column">
                          <FormatDesc description={shop.description} />
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-muted fw-bold mb-1 fs-6">
                            {shop.address}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                        <input
                          className="form-check-input h-30px w-60px"
                          checked={shop.is_active}
                          onClick={() => handleToggleActive(shop.id)}
                          type="checkbox"
                          id={`flexSwitch${shop.id}`}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      <Link
                        to="/marketplace/toko/edit-toko"
                        state={{
                          id: shop.id,
                          name: shop.name,
                          photo: shop.photo,
                          phone: shop.phone,
                          owner: shop.owner,
                          address: shop.address,
                          province: shop.province,
                          city: shop.city,
                          district: shop.district,
                          subdistrict: shop.subdistrict,
                          zip_code: shop.zip_code,
                          address_detail: shop.address_detail,
                          lat: shop.lat,
                          lng: shop.lng,
                          description: shop.description,
                          is_both: shop.is_both,
                          is_free_shipping: shop.is_free_shipping,
                          is_active: shop.is_active,
                          is_open: shop.is_open,
                          is_verified: shop.is_verified,
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                      >
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </Link>
                      <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={`#kt_modal_${shop.id}`}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </button>
                      <DeleteModal
                        id={shop.id}
                        name={shop.name}
                        onDelete={handleDeleteShop}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    {zeroData ? (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalRecords={meta.total}
            recordsPerPage={meta.per_page}
            currentPage={meta.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </KTCardBody>
    </div>
  );
};

export { Shop };
