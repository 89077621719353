import React, { ChangeEvent, useEffect, useState } from "react";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { Image } from "antd";
import RupiahFormat from "../../global/Formatrupiah";
import { AlertMessage } from "../../global/AlertMessage";

type Props = {
  id: number;
};

interface Variant {
  id: number;
  name: string;
  is_active: boolean;
  subs: VariantSub[];
}

interface VariantSub {
  id: number;
  photo: string;
  name: string;
}

interface Detail {
  id: number;
  sub_name_1: string;
  sub_name_2: string;
  sub_id_1: number;
  sub_id_2: number;
  price: number;
  stock: number;
  is_multiple_price: boolean;
}

const initialNewDetails = {
  sub_name_1: "",
  sub_name_2: "",
  sub_id_1: 0,
  sub_id_2: 0,
  price: 0,
  stock: 0,
  is_multiple_price: false,
};

interface Data {
  variants: Variant[];
  details: Detail[];
}

const EditVariantForm: React.FC<Props> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [data, setData] = useState<Data | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedVariantId, setEditedVariantId] = useState<number | null>(null);
  const [editedSubVariantId, setEditedSubVariantId] = useState<number | null>(
    null
  );
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const MAX_FILE_SIZE_MB = 2;

  const [formData, setFormData] = useState<{
    name: string;
    is_active: boolean;
  }>({
    name: "",
    is_active: false,
  });

  const [subVarianData, setSubVarianData] = useState<{
    name: string;
    photo: string;
  }>({
    photo: "",
    name: "",
  });

  useEffect(() => {
    if (currentUser?.token) {
      getProductVariant(id);
    }
  }, [currentUser]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getProductVariant = async (id: number) => {
    setLoading(true);

    const resProductVariant = await API.GetProductVariant(token, id);
    if (resProductVariant.status === 200) {
      const sortedVariants = resProductVariant.data.data.variants.sort(
        (a: Variant, b: Variant) => a.id - b.id
      );
      setData({ ...resProductVariant.data.data, variants: sortedVariants });
      setDetails(resProductVariant.data.data.details);
    }

    setLoading(false);
  };

  const handleUpdateProductVariant = async () => {
    if (!editedVariantId) return;
    setLoading(true);

    const formDataAPI = new FormData();
    formDataAPI.append("name", formData.name);
    formDataAPI.append("is_active", String(formData.is_active));

    const res = await API.UpdateProductVariant(
      formDataAPI,
      token,
      editedVariantId
    );
    if (res.status === 200) {
      await getProductVariant(id);
      setIsEditing(false);
      setEditedVariantId(null);
      setSuccessMessage(res.data.message);
      setShowAlert(true);
    } else {
      setErrorMessage(res.message);
      setShowAlertError(true);
    }

    setLoading(false);
  };

  const handleUpdateProductSubVariant = async () => {
    if (!editedSubVariantId) return;

    setLoading(true);

    const formDataAPI = new FormData();

    if (photoFile) {
      formDataAPI.append("photo", photoFile);
    }

    formDataAPI.append("name", subVarianData.name);

    const res = await API.UpdateProductSubVariant(
      formDataAPI,
      token,
      editedSubVariantId
    );

    if (res.status === 200) {
      await getProductVariant(id);
      setIsEditing(false);
      setEditedSubVariantId(null);
      setSuccessMessage(res.data.message);
      setShowAlert(true);
    } else {
      setErrorMessage(res.message);
      setShowAlertError(true);
      setLoading(false);
    }
  };

  const handleEditVariant = (variant: Variant) => {
    setIsEditing(true);
    setEditedVariantId(variant.id);
    setFormData({ name: variant.name, is_active: variant.is_active });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedVariantId(null);
  };

  const handleEditSubVariant = (subvariant: VariantSub) => {
    setIsEditing(true);
    setEditedSubVariantId(subvariant.id);
    setPreviewPhoto("");

    setSubVarianData({ photo: subvariant.photo, name: subvariant.name });
  };

  const handleCancelSubEdit = () => {
    setIsEditing(false);
    setEditedSubVariantId(null);
  };

  const initialDetails: Detail[] = [];
  const [editedDetailId, setEditedDetailId] = useState<number | null>(null);
  const [details, setDetails] = useState<Detail[]>(initialDetails);
  const [newDetails, setNewDetails] = useState(initialNewDetails);

  const handleAddOption = () => {
    const newDetail: Detail = {
      id: details.length + 1, // Generate unique id based on current length (example)
      sub_name_1: newDetails.sub_name_1,
      sub_name_2: newDetails.sub_name_2,
      sub_id_1: newDetails.sub_id_1,
      sub_id_2: newDetails.sub_id_2,
      price: newDetails.price,
      stock: newDetails.stock,
      is_multiple_price: newDetails.is_multiple_price,
    };

    setDetails([...details, newDetail]);
    setNewDetails(initialNewDetails); // Reset newDetails state after adding
  };

  const handleEditDetailVariant = (details: Detail) => {
    setEditedDetailId(details.id);
    setNewDetails({
      sub_name_1: details.sub_name_1,
      sub_name_2: details.sub_name_2,
      sub_id_1: details.sub_id_1,
      sub_id_2: details.sub_id_2,
      price: details.price,
      stock: details.stock,
      is_multiple_price: details.is_multiple_price,
    });
  };

  const handleCancelDetailEdit = () => {
    setEditedDetailId(null);
    setNewDetails(initialNewDetails); // Reset newDetails state
  };

  const handleUpdateProductDetailVariant = () => {
    // Implement your update logic here
    // You can use editedDetailId to identify the item being updated
    // and use newDetails to get the updated data
    // Example of updating state after API call:
    // setDetails(updatedDetails);
    // setEditedDetailId(null);
  };

  const handleDeleteVariant = (id: number) => {
    setDetails(details.filter((detail) => detail.id !== id));
  };

  const handleUpdateProduct = async () => {
    setLoading(true);

    const formDataAPI = new FormData();

    formDataAPI.append("details[]", JSON.stringify(details));

    const res = await API.UpdateProduct(formDataAPI, token);
    if (res.status === 200) {
      await getProductVariant(id);

      setLoading(false);
    } else {
    }
  };

  // const handleEditDetailVariant = (details: Detail) => {
  //   setIsEditing(true)
  //   setEditedDetailId(details.id)
  //   setDetailData({
  //     sub_name_1: details.sub_name_1,
  //     sub_name_2: details.sub_name_2,
  //     sub_id_1: details.sub_id_1,
  //     sub_id_2: details.sub_id_2,
  //     price: details.price,
  //     stock: details.stock,
  //     is_multiple_price: details.is_multiple_price,
  //   })
  // }

  // const handleChangeDetailVariant = (event: ChangeEvent<HTMLSelectElement>) => {
  //   const {name, value} = event.target

  //   // Menangani perubahan nilai variant 1
  //   if (name === 'variant1') {
  //     setDetailData((prevState) => ({
  //       ...prevState,
  //       sub_id_1: parseInt(value), // Mengubah string menjadi number
  //     }))
  //   }

  //   // Menangani perubahan nilai variant 2
  //   else if (name === 'variant2') {
  //     setDetailData((prevState) => ({
  //       ...prevState,
  //       sub_id_2: parseInt(value), // Mengubah string menjadi number
  //     }))
  //   }
  // }

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
        setErrorMessage(
          `Ukuran file harus kurang dari ${MAX_FILE_SIZE_MB} MB.`
        );
      } else {
        setPhotoFile(file);
        setPreviewPhoto(URL.createObjectURL(file));
      }
    }
  };

  const handleChangeSub = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSubVarianData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      {/* Product Variant */}
      <div
        id="kt_ecommerce_add_product_form"
        className="form d-flex flex-column flex-lg-row mb-10"
      >
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          <div className="tab-content">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id={`kt_ecommerce_add_product`}
                role="tabpanel"
              >
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Produk Varian</h2>
                    </div>
                  </div>
                  <div className="card card-flush py-0">
                    <div className="card-body pt-0">
                      <div id={`kt_job_`} className="collapse show fs-6 ms-1">
                        <div className="row row-cols-1 row-cols-md-2 border border-dashed rounded pt-3 pb-1 px-2 mb-6 mh-300px overflow-scroll">
                          {data?.variants.map((variant) => (
                            <div
                              key={variant.id}
                              className={`col my-2`}
                              data-kt-ecommerce-edit-order-filter="product"
                              data-kt-ecommerce-edit-order-id={`product_${variant.id}`}
                            >
                              <div
                                className={`d-flex align-items-center border border-dashed rounded p-3  ${
                                  isEditing && variant.id === editedVariantId
                                    ? "border-danger"
                                    : ""
                                }`}
                              >
                                <div className="ms-5 flex-grow-1">
                                  <span className="text-gray-800 fs-5 fw-bold">
                                    {variant.name}
                                  </span>
                                </div>
                                <div>
                                  {!(
                                    isEditing && variant.id === editedVariantId
                                  ) && (
                                    <button
                                      className="btn btn-sm btn-light me-2"
                                      onClick={() => handleEditVariant(variant)}
                                    >
                                      Edit
                                    </button>
                                  )}

                                  <button
                                    className="btn btn-sm btn-danger"
                                    // onClick={() => handleDeleteVariant(variant.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {editedVariantId && (
                        <div className="collapse show fs-6 ms-1">
                          <div className="row row-cols-1 border border-dashed rounded pt-4 pb-4 px-2 mh-300px overflow-scroll">
                            <form
                              id={`kt_ecommerce_add_product_form_${editedVariantId}`}
                              className="form d-flex flex-column flex-lg-row"
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateProductVariant();
                              }}
                            >
                              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                <div className="tab-content">
                                  <div className="mb-4 fv-row">
                                    <label className="required form-label">
                                      Nama Variant
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control mb-2"
                                      placeholder="Product name"
                                      value={formData.name}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <label className="fs-6 fw-semibold mb-0">
                                      Tampilkan
                                    </label>
                                    <div className="form-check form-switch form-check-custom form-check-solid mb-0">
                                      <input
                                        className="form-check-input"
                                        name="is_active"
                                        type="checkbox"
                                        checked={formData.is_active}
                                        id={`kt_modal_add_customer_billing_${editedVariantId}`}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn btn-sm btn-light me-4"
                                    onClick={handleCancelEdit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    id="kt_ecommerce_add_product_submit"
                                    className="btn btn-sm btn-success"
                                    onClick={handleUpdateProductVariant}
                                  >
                                    <span className="indicator-label">
                                      Simpan
                                    </span>
                                    <span className="indicator-progress">
                                      Sabar boss...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Product Variant */}

      {/* Product Sub Variant */}
      <div
        id="kt_ecommerce_add_product_form"
        className="form d-flex flex-column flex-lg-row mb-10"
      >
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          <div className="tab-content">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id={`kt_ecommerce_add_product`}
                role="tabpanel"
              >
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Produk Sub Varian</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    {data?.variants.map((variant) => (
                      <div className="m-0" key={variant.id}>
                        <div
                          className="d-flex align-items-center collapsible py-3 toggle mb-0"
                          data-bs-toggle="collapse"
                          data-bs-target={`#kt_job_${variant.id}`}
                        >
                          <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                            <i className="fa fa-duotone fa-minus-square toggle-on text-primary fs-1">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            <i className="fa fa-duotone fa-plus-square toggle-off fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </div>
                          <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                            {variant.name}
                          </h4>
                        </div>
                        <div
                          id={`kt_job_${variant.id}`}
                          className="collapse show fs-6 ms-1"
                        >
                          <div
                            className="row row-cols-1 row-cols-md-2 border border-dashed rounded pt-3 pb-1 px-2 mb-6 mh-300px overflow-scroll"
                            id={`kt_ecommerce_edit_order_selected_products_${variant.id}`}
                          >
                            {variant.subs.map((sub) => (
                              <div
                                key={sub.id}
                                className="col my-2"
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id={`product_${sub.id}`}
                              >
                                <div
                                  className={`d-flex align-items-center border border-dashed rounded p-3  ${
                                    isEditing && sub.id === editedSubVariantId
                                      ? "border-danger"
                                      : ""
                                  }`}
                                >
                                  <span className="symbol symbol-50px">
                                    <span
                                      className="symbol-label"
                                      style={{
                                        backgroundImage: `url(${sub.photo})`,
                                      }}
                                    />
                                  </span>

                                  <div className="ms-5 flex-grow-1">
                                    <span className="text-gray-800 fs-5 fw-bold">
                                      {sub.name}
                                    </span>
                                  </div>
                                  <div>
                                    {!(
                                      isEditing && sub.id === editedSubVariantId
                                    ) && (
                                      <button
                                        className="btn btn-sm btn-light me-2"
                                        onClick={() =>
                                          handleEditSubVariant(sub)
                                        }
                                      >
                                        Edit
                                      </button>
                                    )}

                                    <button
                                      className="btn btn-sm btn-danger"
                                      // onClick={() => handleDeleteVariant(variant.id)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="separator separator-dashed" />
                        {isEditing &&
                          variant.subs.some(
                            (sub) => sub.id === editedSubVariantId
                          ) && (
                            <div className="collapse show fs-6 ms-1 mt-6">
                              <div className="row row-cols-1 border border-dashed rounded pt-4 pb-4 px-2 mh-500px overflow-scroll">
                                <form
                                  id={`kt_ecommerce_edit_subvariant_form_${editedSubVariantId}`}
                                  className="form d-flex flex-column flex-lg-row"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdateProductSubVariant();
                                  }}
                                >
                                  <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                    <div className="tab-content">
                                      <label className="required form-label">
                                        Photo
                                      </label>
                                      <div className="mb-4 fv-row">
                                        <div
                                          className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                                          data-kt-image-input="true"
                                          style={{
                                            backgroundImage: `url('${
                                              previewPhoto ||
                                              subVarianData.photo ||
                                              ""
                                            }')`,
                                          }}
                                        >
                                          <Image
                                            className="image-input-wrapper w-150px h-150px"
                                            src={
                                              previewPhoto ||
                                              subVarianData.photo ||
                                              ""
                                            }
                                            alt="Selected Image"
                                          />

                                          <label
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow"
                                            data-kt-image-input-action="change"
                                            data-bs-toggle="tooltip"
                                            title="Change avatar"
                                          >
                                            <i className="bi-outline bi-pencil fs-7" />
                                            <input
                                              type="file"
                                              name="avatar"
                                              accept=".png, .jpg, .jpeg"
                                              onChange={handleChangePhoto}
                                            />
                                            <input
                                              type="hidden"
                                              name="avatar_remove"
                                            />
                                          </label>
                                        </div>
                                      </div>

                                      <div className="mb-4 fv-row">
                                        <label className="required form-label">
                                          Nama Sub Variant
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          className="form-control mb-2"
                                          placeholder="Sub variant name"
                                          value={subVarianData.name}
                                          onChange={handleChangeSub}
                                        />
                                      </div>

                                      <div className="d-flex justify-content-end">
                                        <button
                                          className="btn btn-sm btn-light me-4"
                                          onClick={handleCancelSubEdit}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="submit"
                                          id="kt_ecommerce_add_product_submit"
                                          className="btn btn-sm btn-success"
                                          onClick={
                                            handleUpdateProductSubVariant
                                          }
                                        >
                                          <span className="indicator-label">
                                            Simpan
                                          </span>
                                          <span className="indicator-progress">
                                            Sabar boss...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Product Variant */}

      {/* Product Detail Variant */}
      <div
        id="kt_ecommerce_add_product_form"
        className="form d-flex flex-column flex-lg-row mb-10"
      >
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id={`kt_ecommerce_add_product`}
              role="tabpanel"
            >
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Produk Varian</h2>
                  </div>
                  <div className="form-group mt-5">
                    <button
                      type="button"
                      data-repeater-create=""
                      className="btn btn-sm btn-light-primary"
                      onClick={handleAddOption}
                    >
                      <i className="ki-duotone ki-plus fs-2" />
                      Tambah Varian Detail
                    </button>
                  </div>
                </div>
                <div className="card card-flush py-0">
                  <div className="card-body pt-0">
                    <div id={`kt_job_`} className="collapse show fs-6 ms-1">
                      <div className="row row-cols-1 border border-dashed rounded pt-3 pb-1 px-2 mb-6 ">
                        {details.map((detail) => (
                          <div
                            key={detail.id}
                            className={`col my-2`}
                            data-kt-ecommerce-edit-order-filter="product"
                            data-kt-ecommerce-edit-order-id={`product_${detail.id}`}
                          >
                            <div
                              className={`d-flex align-items-center border border-dashed rounded p-3  ${
                                editedDetailId === detail.id
                                  ? "border-danger"
                                  : ""
                              }`}
                            >
                              <div className="ms-5 flex-grow-1">
                                <span className="text-gray-800 fs-5 fw-bold">
                                  {/* Check if both sub_name_1 and sub_name_2 exist */}
                                  {detail.sub_name_1 && detail.sub_name_2 ? (
                                    <>
                                      {detail.sub_name_1} - {detail.sub_name_2}
                                    </>
                                  ) : (
                                    // If only sub_name_1 exists or neither exists
                                    detail.sub_name_1 || detail.sub_name_2
                                  )}
                                </span>
                              </div>
                              <div>
                                {!editedDetailId && (
                                  <button
                                    className="btn btn-sm btn-light me-2"
                                    onClick={() =>
                                      handleEditDetailVariant(detail)
                                    }
                                  >
                                    Edit
                                  </button>
                                )}

                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleDeleteVariant(detail.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {editedDetailId && (
                      <div className="collapse show fs-6 ms-1">
                        <div className="row row-cols-1 border border-dashed rounded pt-4 pb-4 px-2">
                          <form
                            id={`kt_ecommerce_add_product_form_${editedDetailId}`}
                            className="form d-flex flex-column flex-lg-row"
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleUpdateProductDetailVariant();
                            }}
                          >
                            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                              <div className="tab-content">
                                <div className="mb-4 fv-row">
                                  <label className="required form-label">
                                    Nama Variant 1
                                  </label>
                                  <select
                                    name="variant1"
                                    className="form-select mb-2"
                                    value={newDetails.sub_id_1}
                                    onChange={(e) =>
                                      setNewDetails({
                                        ...newDetails,
                                        sub_id_1: Number(e.target.value),
                                      })
                                    }
                                  >
                                    <option value={0}>Pilih Variant 1</option>
                                    {data?.variants &&
                                      data.variants.length > 0 &&
                                      data.variants[0].subs.map((sub) => (
                                        <option value={sub.id} key={sub.id}>
                                          {sub.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>

                                {data?.variants && data.variants.length > 1 && (
                                  <div className="mb-4 fv-row">
                                    <label className="required form-label">
                                      Nama Variant 2
                                    </label>
                                    <select
                                      name="variant2"
                                      className="form-select mb-2"
                                      value={newDetails.sub_id_2}
                                      onChange={(e) =>
                                        setNewDetails({
                                          ...newDetails,
                                          sub_id_2: Number(e.target.value),
                                        })
                                      }
                                    >
                                      <option value={0}>Pilih Variant 2</option>
                                      {data.variants[1].subs.map((sub) => (
                                        <option value={sub.id} key={sub.id}>
                                          {sub.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-4 fv-row">
                                  <label className="required form-label">
                                    Harga
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control mb-2"
                                    placeholder="Harga"
                                    value={newDetails.price}
                                    onChange={(e) =>
                                      setNewDetails({
                                        ...newDetails,
                                        price: Number(e.target.value),
                                      })
                                    }
                                  />
                                </div>
                                <div className="mb-4 fv-row">
                                  <label className="required form-label">
                                    Stock
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control mb-2"
                                    placeholder="Stock"
                                    value={newDetails.stock}
                                    onChange={(e) =>
                                      setNewDetails({
                                        ...newDetails,
                                        stock: Number(e.target.value),
                                      })
                                    }
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                  <label className="fs-6 fw-semibold mb-0">
                                    Harga Borongan
                                  </label>
                                  <div className="form-check form-switch form-check-custom form-check-solid mb-0">
                                    <input
                                      className="form-check-input"
                                      name="is_active"
                                      type="checkbox"
                                      checked={newDetails.is_multiple_price}
                                      id={`kt_modal_add_customer_billing_${editedDetailId}`}
                                      onChange={(e) =>
                                        setNewDetails({
                                          ...newDetails,
                                          is_multiple_price: e.target.checked,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-sm btn-light"
                                  onClick={handleCancelDetailEdit}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary ms-2"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Product Detail Variant */}
    </>
  );
};

export { EditVariantForm };
