import React, { useState, useEffect, useCallback } from "react";
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { EditVersion } from "../../modules/settings/Version/EditVersion";
import usePageTitle from "../../modules/global/PageTitle";
import { Modal, Button, Form, Alert } from "react-bootstrap";

const correctPassword = "8484";

const PasswordModal: FC<{
  show: boolean;
  password: string;
  showAlert: boolean;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onHide: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  show,
  password,
  showAlert,
  onPasswordChange,
  onSubmit,
  onHide,
  onKeyDown,
}) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Masukkan Kode Akses</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {showAlert && (
        <Alert variant="danger">Kode akses salah. Silakan coba lagi.</Alert>
      )}
      <Form>
        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Masukkan Kode Akses"
            value={password}
            onChange={onPasswordChange}
            onKeyDown={onKeyDown}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Batal
      </Button>
      <Button variant="primary" onClick={onSubmit}>
        Kirim
      </Button>
    </Modal.Footer>
  </Modal>
);

const VersionWrapper: FC = () => {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const intl = useIntl();
  usePageTitle("Versi");

  useEffect(() => {
    document.body.style.overflow = isPasswordModalVisible ? "hidden" : "auto";
  }, [isPasswordModalVisible]);

  const handlePasswordSubmit = useCallback(() => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setIsPasswordModalVisible(false);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setPassword("");
      }, 2000);
    }
  }, [password]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handlePasswordSubmit();
      }
    },
    [handlePasswordSubmit]
  );

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleModalHide = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]} />
      {isAuthenticated && <EditVersion />}
      <PasswordModal
        show={isPasswordModalVisible}
        password={password}
        showAlert={showAlert}
        onPasswordChange={handlePasswordChange}
        onSubmit={handlePasswordSubmit}
        onHide={handleModalHide}
        onKeyDown={handleKeyDown}
      />
    </>
  );
};

export { VersionWrapper };
