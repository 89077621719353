/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";
import API from "../../../../utils/apiProvider";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<{
    name: string;
    email: string;
    photo: string;
  } | null>(null);
  const token = currentUser?.token;

  useEffect(() => {
    if (token) {
      GetProfile();
    }
  }, [token]);

  const GetProfile = async () => {
    setLoading(true);

    try {
      const resProfileData = await API.GetProfile(token);

      if (resProfileData.status === 200) {
        const { name, email, photo } = resProfileData.data.data;
        setUserData({ name, email, photo });
        setLoading(false);
      } else if (resProfileData.status === 401) {
        logout();
      } else {
        console.error("Error fetching home data:", resProfileData);
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching home data:", error);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img
              alt="Profile"
              src={
                userData
                  ? userData.photo
                  : toAbsoluteUrl("/media/avatars/300-1.jpg")
              }
            />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {userData ? userData.name : "Loading..."}
            </div>
            <span className="fw-bold text-muted fs-7">
              {userData ? userData.email : "Loading..."}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a onClick={handleLogout} className="menu-link px-5">
          {" "}
          Keluar
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
