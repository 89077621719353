/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { DetailWhitdrawModal } from "./modal/DetailWhitdrawModal";
import { UpdateStatusWhitdrawModal } from "./modal/UpdateStatusWhitdrawModa";
import { AlertMessage } from "../../global/AlertMessage";
import { Pagination } from "../../global/Pagination";
import RupiahFormat from "../../global/Formatrupiah";
import { Empty, Spin } from "antd";
import SearchInput from "../../global/SearchInput";

type Props = {
  className: string;
};

type Transaction = {
  id: number;
  api_id: number | null;
  name: string;
  bank_account: string;
  account_holder: string;
  nominal: number;
  fee: number;
  total: number;
  status: string;
  description: string | null;
  date: string;
  update: string;
};

interface Meta {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const WhitdrawComponent: React.FC<Props> = ({ className }) => {
  const [withdrawData, setWithdrawData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [meta, setMetaData] = useState<Meta>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [startIndex, setStartIndex] = useState(0);
  const [zeroData, setZeroData] = useState("");
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    fetchTransactions();
  }, [searchQuery, meta.page, meta.per_page]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const fetchTransactions = async () => {
    setLoading(true);
    const resWhitdraw = await API.GetWithdraw(
      token,
      searchQuery,
      meta.page,
      meta.per_page
    );
    if (resWhitdraw.status === 200 && resWhitdraw.data.data.length > 0) {
      setWithdrawData(resWhitdraw.data.data);
      setMetaData(resWhitdraw.data.meta);
    } else {
      setWithdrawData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resWhitdraw.data.message);
    }
    setLoading(false);
  };

  const selectedTransaction = withdrawData.find(
    (transaction) => transaction.id === selectedTransactionId
  );

  const handleupdateStatusWhitdraw = async (formData: any, id: number) => {
    setLoading(true);

    const { status, reason } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("status", status);
    formDataAPI.append("reason", reason);

    const res = await API.UpdateStatusWithdraw(formDataAPI, token, id);

    if (res.status === 200) {
      await fetchTransactions();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  return (
    <div className="col">
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      {isModalOpen && (
        <DetailWhitdrawModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          id={selectedTransactionId}
          whitdraw={selectedTransaction as Transaction}
        />
      )}
      {isUpdateModalOpen && (
        <UpdateStatusWhitdrawModal
          isOpen={isUpdateModalOpen}
          onClose={handleUpdateCloseModal}
          handleupdateStatusWhitdraw={handleupdateStatusWhitdraw}
          id={selectedTransactionId}
          whitdraw={withdrawData.find(
            (category) => category.id === selectedTransactionId
          )}
        />
      )}
      <div className={`card ${className}`}>
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Penarikan</h3>
          </div>
        </div>
        <div className="separator separator mb-6"></div>
        <div className="card-header border-0">
          <SearchInput placeholder="Search" onSearch={handleSearch} />
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                  <th className="min-w-60px rounded-start text-center">No</th>
                  <th className="min-w-125px text-center">Nama</th>
                  <th className="min-w-125px text-center">Tanggal</th>
                  <th className="min-w-125px text-center">Tujuan</th>
                  <th className="min-w-125px text-center">Nominal</th>
                  <th className="min-w-125px text-center">Status</th>
                  <th className="min-w-25px rounded-end text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : withdrawData.length > 0 ? (
                  withdrawData.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold mb-1 fs-6">
                              {startIndex + index + 1}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="text-center">
                        <span className="text-dark fw-bold d-block mb-1 fs-6">
                          {transaction.name}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-dark fw-bold d-block mb-1 fs-6">
                          {formatDate(transaction.date)}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center flex-column">
                          <span className="text-dark fw-bold mb-1 fs-6">
                            {transaction.account_holder}
                          </span>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {transaction.bank_account}
                          </span>
                        </div>
                      </td>

                      <td className="text-center">
                        <span className="text-dark fw-bold d-block mb-1 fs-6">
                          <RupiahFormat value={transaction.nominal} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge ${
                            transaction.status === "pending"
                              ? "badge-light-primary"
                              : transaction.status === "approved"
                              ? "badge-light-warning"
                              : transaction.status === "successed"
                              ? "badge-light-success"
                              : transaction.status === "rejected"
                              ? "badge-light-danger"
                              : transaction.status === "cancelled"
                              ? "badge-light-danger"
                              : ""
                          }`}
                        >
                          {transaction.status === "pending"
                            ? "Pending"
                            : transaction.status === "approved"
                            ? "Diterima"
                            : transaction.status === "rejected"
                            ? "Ditolak"
                            : transaction.status === "successed"
                            ? "Sukses"
                            : transaction.status === "cancelled"
                            ? "Dibatalkan"
                            : "Unknown"}
                        </span>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            if (transaction.status === "pending") {
                              setSelectedTransactionId(transaction.id);
                              handleUpdateOpenModal();
                            }
                          }}
                          className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 ${
                            transaction.status === "pending" ? "" : "d-none"
                          }`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedTransactionId(transaction.id);
                            handleOpenModal();
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        >
                          <KTSVG
                            path="/media/icons/duotune/ecommerce/ecm008.svg"
                            className="svg-icon-3"
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { WhitdrawComponent };
