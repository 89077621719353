import { FC, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "../../modules/auth";
import { MyTasksWidget } from "../../modules/utama/dashboard/TaskWidget";
import usePageTitle from "../../modules/global/PageTitle";
import { IncomeCharts } from "../../modules/utama/dashboard/IncomeChart";
import { TransactionCharts } from "../../modules/utama/dashboard/TransactionChart";
import { NewProduct } from "../../modules/utama/dashboard/NewProduct";
import { TopCategoryCharts } from "../../modules/utama/dashboard/TopCategoryCharts";
import { TopSellingProduct } from "../../modules/utama/dashboard/TopSellingProduct";
import { PieCharts } from "../../modules/utama/dashboard/Chart";
import API from "../../../utils/apiProvider";

type DashboardWidget = {
  buyer: number;
  seller: number;
  expedition: number;
};
type DetailsWidget = {
  shop: number;
  category: number;
  product: number;
  transaction: number;
};

type IncomeData = {
  dates: string[];
  totals: number[];
};

type RangeType = "7" | "30" | "all";

const DashboardPage: FC<{
  data: DashboardWidget;
  details: DetailsWidget;
  dataIncome: IncomeData;
  dataTotalIncome: number;
  loading: boolean;
  range: RangeType;
  setRange: (range: RangeType) => void;
}> = ({
  data,
  details,
  dataIncome,
  dataTotalIncome,
  loading,
  range,
  setRange,
}) => (
  <>
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <PieCharts data={data} />
      <MyTasksWidget data={details} />
    </div>
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <TopCategoryCharts />
      <div className="col-xl-8">
        <IncomeCharts />
      </div>
    </div>
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xl-4">
        <TopSellingProduct />
      </div>
      <div className="col-xl-8">
        <TransactionCharts />
      </div>
    </div>
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-xl-12">
        <NewProduct />
      </div>
    </div>
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<RangeType>("all");

  const [data, setData] = useState<DashboardWidget>({
    buyer: 0,
    seller: 0,
    expedition: 0,
  });
  const [details, setDetails] = useState({
    shop: 0,
    category: 0,
    product: 0,
    transaction: 0,
  });
  const [dataIncome, setDataIncome] = useState<IncomeData>({
    dates: [],
    totals: [],
  });
  const [dataTotalIncome, setDataTotalIncome] = useState(0);

  useEffect(() => {
    if (currentUser?.token) {
      getDataWidget();
    }
  }, [currentUser, range]);

  const getDataWidget = async () => {
    setLoading(true);
    const res = await API.GetHome(currentUser?.token, range);
    if (res.status === 200) {
      setData(res.data.data.users);
      setDetails(res.data.data.details);
      setDataIncome(res.data.data.incomes);
      setDataTotalIncome(res.data.data.income_total);
    } else {
      console.error("Error fetching data:", res);
    }
    setLoading(false);
  };

  usePageTitle("Dashboard");

  return (
    <>
      <DashboardPage
        data={data}
        details={details}
        dataIncome={dataIncome}
        dataTotalIncome={dataTotalIncome}
        loading={loading}
        range={range}
        setRange={setRange}
      />
    </>
  );
};

export { DashboardWrapper };
