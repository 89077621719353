/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";
import RupiahFormat from "../../global/Formatrupiah";
import { Image } from "antd";
import apiProvider from "../../../../utils/apiProvider";
import TrackingDrawer from "./TrackingTransaction";

interface Transaction {
  id: number;
  code: string;
  shop: {
    id: number;
    photo: string;
    name: string;
  };
  products: Array<{
    id: number;
    photo: string;
    name: string;
    variant: string | null;
    qty: number;
    price: number;
  }>;
  shipping: {
    name: string;
    phone: number;
    courier: string;
    service: string;
    resi: string | null;
    address: string;
  };
  payment: {
    bank: string;
    type: string;
    number: string;
    url: string;
    expired: string;
    status: string;
  };
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  fee: number;
  fee_payment: number;
  grand_total: number;
  status: string;
  date: string;
}

interface DetailTransactionUserProps {
  id: number;
  data: Transaction;
}

interface Trace {
  status: string;
  detail: string;
  date: string;
}

interface trackingTransaction {
  id: number;
  resi: string;
  type: string;
  courier: string;
  service: string;
  trace: Trace[];
}

const TransactionDetail: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const state = location.state as DetailTransactionUserProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [trackingTransaction, setTrackingTransaction] =
    useState<trackingTransaction | null>(null);
  const [zeroData, setZeroData] = useState("");
  const { id, data } = state;
  const [showModalTracking, setShowModalTracking] = useState(false);

  useEffect(() => {
    if (currentUser?.token && state.id) {
      fetchTrackingTransaction(state.id);
    }
  }, [currentUser?.token, state.id]);

  const fetchTrackingTransaction = async (id: number) => {
    setLoading(true);
    const response = await apiProvider.TrackingTransaction(
      currentUser?.token,
      id
    );

    if (response.data) {
      setTrackingTransaction(response.data);
    } else {
      setTrackingTransaction(null);
      setZeroData(response.message);
    }
    setLoading(false);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  return (
    <>
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Detail Pesanan</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-calendar fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      Kode Transaksi
                    </div>
                  </td>
                  <td className="fw-bold text-end">{data.code}</td>
                </tr>

                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-courier fs-2 me-2 ">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Tanggal
                    </div>
                  </td>
                  <td className="fw-bold text-end">{formatDate(data.date)}</td>
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-shop fs-2 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                      Toko
                    </div>
                  </td>

                  <div className="d-flex align-items-center justify-content-end">
                    <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                      <div className="symbol-label">
                        <img
                          src={data.shop.photo}
                          alt="Dan Wilson"
                          className="w-100"
                        />
                      </div>
                    </div>
                    <span className="text-gray-600">{data.shop.name}</span>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Pengiriman</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-truck fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                      </i>
                      Resi
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        title="View the shipping manifest generated by this order."
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </span>
                    </div>
                  </td>
                  <td className="fw-bold text-end">
                    <span className="text-gray-600">
                      {data.shipping.resi
                        ? `${data.shipping.resi}`
                        : "Resi belum tersedia!"}
                    </span>
                    {data.shipping.resi &&
                      data.shipping.courier !== "Kurir Toko" && (
                        <button
                          onClick={() => setShowModalTracking(true)}
                          className="btn btn-light-primary btn-sm ms-4"
                        >
                          Lacak
                        </button>
                      )}
                  </td>
                  <TrackingDrawer
                    trackingTransaction={trackingTransaction}
                    show={showModalTracking}
                    onHide={() => setShowModalTracking(false)}
                  />
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-courier fs-2 me-2 ">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Kurir
                    </div>
                  </td>
                  <td className="fw-bold text-end">
                    {data.shipping.courier} - {data.shipping.service}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-profile-circle fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                      Penerima
                    </div>
                  </td>
                  <td className="fw-bold text-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="text-gray-600">
                        {data.shipping.name}
                      </span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-phone fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      Nomor Telepon
                    </div>
                  </td>
                  <td className="fw-bold text-end">{data.shipping.phone}</td>
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-telephone-geolocation fs-2 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Alamat
                    </div>
                  </td>
                  <td className="fw-bold text-end">
                    <a className="text-gray-600">{data.shipping.address}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Pembayaran</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-wallet fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                      Metode Pembayaran
                    </div>
                  </td>
                  <td className="fw-bold text-end">
                    {data.payment.bank} - {data.payment.type}
                  </td>
                </tr>

                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-bill fs-2 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                      Status Pembayaran
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        title="Reward value earned by customer when purchasing this order"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </span>
                    </div>
                  </td>
                  <td className="fw-bold text-primary text-end">
                    <div className="d-flex col-row justify-content-end align-items-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6 ms-4">
                        <span
                          className={`badge ${
                            data.payment.status === "PENDING"
                              ? "badge-light-primary"
                              : data.payment.status === "SUCCESSFUL"
                              ? "badge-light-success"
                              : data.payment.status === "CANCELLED"
                              ? "badge-light-warning"
                              : data.payment.status === "FAILED"
                              ? "badge-light-danger"
                              : "badge-light-danger"
                          }`}
                        >
                          {data.payment.status === "PENDING"
                            ? "Menunggu"
                            : data.payment.status === "SUCCESSFUL"
                            ? "Sukses"
                            : data.payment.status === "CANCELLED"
                            ? "Dibatalkan"
                            : data.payment.status === "FAILED"
                            ? "Gagal"
                            : "-"}
                        </span>
                      </span>
                      {data.payment.bank !== "Baba" && (
                        <a
                          className="btn btn-light-primary btn-sm ms-4"
                          href={data.payment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Klik disini
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="kt_ecommerce_sales_order_summary"
            role="tab-panel"
          >
            <div className="d-flex flex-column gap-7 gap-lg-10 mt-12">
              <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Detail Produk</h2>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                      <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                          <th className="min-w-175px">Product</th>
                          <th className="min-w-70px text-end">Qty</th>
                          <th className="min-w-100px text-end">Harga</th>
                          <th className="min-w-100px text-end">Total</th>
                        </tr>
                      </thead>
                      <tbody className="fw-semibold text-gray-600">
                        {!data.products.length ? (
                          loading ? (
                            <tr>
                              <td
                                colSpan={8}
                                className="text-center text-muted"
                              >
                                <span className="text-muted fw-bold mb-1 fs-6">
                                  {}
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                colSpan={8}
                                className="text-center text-muted"
                              >
                                <span className="text-muted fw-bold mb-1 fs-6">
                                  No products found
                                </span>
                              </td>
                            </tr>
                          )
                        ) : (
                          data.products.map((product, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span className="symbol symbol-50px">
                                      <Image
                                        className="symbol-label"
                                        src={product.photo}
                                      />
                                    </span>
                                    <div className="ms-5">
                                      <span className="fw-bold text-gray-600">
                                        {product.name}
                                      </span>
                                      {product.variant && (
                                        <div className="fw-bold text-gray-600 mt-1">
                                          {product.variant}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end">{product.qty}</td>
                                <td className="text-end">
                                  <RupiahFormat value={product.price} />
                                </td>
                                <td className="text-end">
                                  <RupiahFormat
                                    value={product.qty * product.price}
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))
                        )}
                        <tr>
                          <td colSpan={3} className="text-end">
                            Subtotal
                          </td>
                          <td className="text-end">
                            <RupiahFormat value={data.subtotal} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-end">
                            Biaya Pengiriman
                          </td>
                          <td className="text-end">
                            <RupiahFormat value={data.shipping_cost} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-end">
                            Biaya Penanganan
                          </td>
                          <td className="text-end">
                            <RupiahFormat value={data.fee} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-end">
                            Biaya Pembayaran
                          </td>
                          <td className="text-end">
                            <RupiahFormat value={data.fee_payment} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-end">
                            Biaya Tambahan
                          </td>
                          <td className="text-end">
                            <RupiahFormat value={data.extra_cost} />
                          </td>
                        </tr>

                        <tr>
                          <td
                            colSpan={3}
                            className="fs-3 text-gray-900 text-end"
                          >
                            Grand Total
                          </td>
                          <td className="text-gray-900 fs-3 fw-bolder text-end">
                            <RupiahFormat value={data.grand_total} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export { TransactionDetail };
