/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { Loading } from "../../global/Loading";
import { AddBannerModal } from "./Modal/AddBannerModal";
import { UpdateBannerModal } from "./Modal/UpdateBannerModal";
import { AlertMessage } from "../../global/AlertMessage";
import { Pagination } from "../../global/Pagination";
import { DeleteModal } from "../../global/DeleteModal";
import { Empty, Spin } from "antd";
import SearchInput from "../../global/SearchInput";

type Props = {
  className: string;
};

type CategoryData = {
  id: number;
  photo: string;
  type: string;
  url: string;
  is_active: boolean;
};

const BannerComponents: React.FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();
  const [bannerData, setBannerData] = useState<CategoryData[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [zeroData, setZeroData] = useState("");
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [metaData, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (currentUser?.token) getBanner();
  }, [currentUser, metaData.page, metaData.per_page, searchQuery]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (showAlert || showAlertError) {
      timeout = setTimeout(() => {
        setShowAlert(false);
        setShowAlertError(false);
      }, 4000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getBanner = async () => {
    setLoading(true);
    const resDataBanner = await API.GetBanner(
      token,
      metaData.page,
      searchQuery,
      metaData.per_page
    );
    if (resDataBanner.data.data.length > 0 && resDataBanner.status === 200) {
      setBannerData(
        resDataBanner.data.data.sort(
          (a: CategoryData, b: CategoryData) => a.id - b.id
        )
      );
      setMetaData(resDataBanner.data.meta);
    } else {
      setBannerData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resDataBanner.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= metaData.total_pages) {
      setMetaData((prevMetaData) => ({ ...prevMetaData, page: pageNumber }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleAddBanner = async (formData: any) => {
    const formDataAPI = new FormData();
    formDataAPI.append("photo", formData.photo);
    formDataAPI.append("type", formData.type);
    formDataAPI.append("url", formData.url);

    const res = await API.AddBanner(formDataAPI, token);

    if (res.status === 200) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.data.message);
    }
  };

  const handleUpdateBanner = async (formData: any, id: number) => {
    const formDataAPI = new FormData();
    if (formData.photo instanceof File)
      formDataAPI.append("photo", formData.photo);
    formDataAPI.append("type", formData.type);
    formDataAPI.append("url", formData.url);
    const res = await API.UpdateBanner(formDataAPI, token, id);
    if (res.status === 200) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.data.message);
    }
  };

  const handleDeleteUser = async (id: number) => {
    const res = await API.DeleteBanner(token, id);
    if (res.status === 200) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.data.message);
    }
  };

  const handleSearch = (query: string) => setSearchQuery(query);

  const handleToggleActive = async (id: number) => {
    const res = await API.UpdateStatusBanner(token, id);
    if (res.status === 200) {
      setBannerData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, is_active: !item.is_active } : item
        )
      );
      setSuccessMessage(res.data.message);
      setShowAlert(true);
    } else {
      setShowAlertError(true);
      setErrorMessage(res.message);
    }
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}
      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className={`card ${className}`}>
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Banner</h3>
          </div>
        </div>
        <div className="separator separator-dashed"></div>
        <div className="card-header border-0 pt-5">
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-light-primary"
              onClick={() => setIsModalOpen(true)}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Tambah Banner
            </button>
          </div>
          <div className="d-flex align-items-center position-relative mt-4 mb-4">
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
          {isModalOpen && (
            <AddBannerModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              handleAddBanner={handleAddBanner}
            />
          )}
          {isUpdateModalOpen && (
            <UpdateBannerModal
              isOpen={isUpdateModalOpen}
              onClose={() => setIsUpdateModalOpen(false)}
              handleUpdateBanner={handleUpdateBanner}
              id={selectedCategoryId}
              banner={bannerData.find(
                (banner) => banner.id === selectedCategoryId
              )}
            />
          )}
        </div>

        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted bg-light text-center">
                  <th className="min-w-100px text-center align-middle rounded-start">
                    No
                  </th>
                  <th className="min-w-125px text-center align-middle">
                    Gambar
                  </th>
                  <th className="min-w-125px text-center align-middle">Type</th>
                  <th className="min-w-125px text-center align-middle">URL</th>
                  <th className="min-w-125px text-center align-middle">
                    Status
                  </th>
                  <th className="min-w-100px text-center align-middle rounded-end">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : bannerData.length > 0 ? (
                  bannerData.map((banner, index) => (
                    <tr key={banner.id}>
                      <td className="text-center">
                        <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                          {index + 1}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="symbol symbol-150px">
                          <span className="symbol-label bg-light">
                            <img
                              src={banner.photo}
                              className="h-100 rounded"
                              alt=""
                              style={{
                                objectFit: "cover",
                                aspectRatio: "16/9",
                              }}
                            />
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <span className="text-dark fw-bold">
                          {banner.type === "home"
                            ? "Home"
                            : banner.type === "product"
                            ? "Produk"
                            : banner.type}
                        </span>
                      </td>

                      <td className="text-center">
                        <span className="text-dark fw-bold">{banner.url}</span>
                      </td>
                      <td className="text-center">
                        <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                          <input
                            className="form-check-input h-30px w-60px"
                            checked={banner.is_active}
                            onChange={() => handleToggleActive(banner.id)}
                            type="checkbox"
                            id={`flexSwitch${banner.id}`}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            setSelectedCategoryId(banner.id);
                            setIsUpdateModalOpen(true);
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_${banner.id}`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <DeleteModal
                          id={banner.id}
                          name="Banner"
                          onDelete={handleDeleteUser}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={metaData.total}
              recordsPerPage={metaData.per_page}
              currentPage={metaData.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { BannerComponents };
