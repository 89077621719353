import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useAuth } from "../../../../app/modules/auth";
import API from "../../../../utils/apiProvider";
import { useThemeMode } from "../../../../_metronic/partials";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { Empty, Spin } from "antd";

type Category = {
  name: string;
  total: number;
};

type CategoriesData = {
  categories: Category[];
};

const TopCategoryCharts: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { currentUser } = useAuth();
  const { mode } = useThemeMode();

  const [range, setRange] = useState<string>("all");
  const initialData: CategoriesData = {
    categories: [{ name: "", total: 0 }],
  };

  const [dataTopCategory, setDataTopCategory] =
    useState<CategoriesData>(initialData);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.token) {
      getDataWidget();
    }
  }, [currentUser, range]);

  useEffect(() => {
    if (chartRef.current && dataTopCategory.categories.length > 0) {
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(dataTopCategory.categories, mode)
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartRef, dataTopCategory, mode]);
  const getDataWidget = async () => {
    setLoading(true);
    const res = await API.GetHome(currentUser?.token, range);
    if (res.status === 200) {
      setDataTopCategory({ categories: res.data.data.categories });
    } else {
      console.error("Error fetching data:", res);
    }
    setLoading(false);
  };

  return (
    <div className="col-xl-4">
      <div className="card">
        <div className="card card-flush h-xl-100 ">
          {/*begin::Header*/}
          <div className="card-header pt-7 border-0">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-800">
                Kategori Terpopuler
              </span>
            </h3>
          </div>

          <div className="card-body">
            {loading ? (
              <Spin />
            ) : dataTopCategory.categories.length > 0 ? (
              <div
                ref={chartRef}
                id="kt_charts_widget_3_chart"
                style={{ height: "300px" }}
              ></div>
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                  <span className="text-muted fw-bold">Data tidak ada</span>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { TopCategoryCharts };

function getChartOptions(categories: Category[], mode: string): ApexOptions {
  const labelColor =
    mode === "light" ? getCSSVariableValue("--bs-gray-800") : "#ffffff";
  const borderColor = getCSSVariableValue("--kt-gray-200");

  const categoryNames = categories.map((category) => category.name);
  const categoryTotals = categories.map((category) => category.total);

  const colors = [
    "#3E97FF",
    "#F1416C",
    "#50CD89",
    "#FFC700",
    "#7239EA",
    "#6C757D",
  ];

  return {
    series: [
      {
        name: "Total terjual",
        data: categoryTotals,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: true,
        distributed: true,
        barHeight: "90",
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetX: 0,

      style: {
        fontSize: "14px",
        fontWeight: "600",
        colors: [
          mode === "light" ? getCSSVariableValue("--bs-gray-800") : "#ffffff",
        ], // Menggunakan array untuk properti colors
      },
    },
    legend: {
      show: false,
    },
    colors: colors.slice(0, categoryNames.length), // Memastikan jumlah warna sesuai dengan jumlah kategori
    xaxis: {
      categories: categoryNames,
      labels: {
        formatter: function (val) {
          return val;
        },
        style: {
          colors:
            mode === "light" ? getCSSVariableValue("--bs-gray-800") : "#ffffff",
          fontSize: "14px",
          fontWeight: "600",
          cssClass: "left",
        },
      },
      axisBorder: {
        show: false,
      },
      tickAmount: 4,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${val.toLocaleString()}`;
        },
        style: {
          colors:
            mode === "light" ? getCSSVariableValue("--bs-gray-800") : "#ffffff",
          fontSize: "14px",
          fontWeight: "600",
        },
        offsetY: 2,
        align: "left",
      },
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      strokeDashArray: 4,
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
  };
}
