import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { UserSkeleton } from "../skeleton/SkeletonUser";
import { DetailTransactionUser } from "./modal/DetailTransactionUser";
import { useAuth } from "../../../auth";
import API from "../../../../../utils/apiProvider";
import { TransactionSkeleton } from "../skeleton/SkeletonTransaction";
import { Pagination } from "../../../global/Pagination";
import RupiahFormat from "../../../global/Formatrupiah";
import { Link } from "react-router-dom";
import { Empty } from "antd";

type TransactionUser = {
  id: number;
  code: string;
  shop: {
    id: number;
    photo: string;
    name: string;
  };
  product: Array<{
    id: number;
    photo: string;
    name: string;
    variant: string | null;
    qty: number;
    price: number;
  }>;
  shipping: {
    name: string;
    phone: number;
    courier: string;
    service: string;
    resi: string | null;
    address: string;
  };
  payment: {
    bank: string;
    type: string;
    number: string;
    url: string;
    expired: string;
  };
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  fee: number;
  grand_total: number;
  status: string;
  date: string;
};

type Props = {
  className: string;
  name: string;
  id: number;
};

const TransactionUserList: React.FC<Props> = ({ className, name, id }) => {
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<TransactionUser[]>([]);
  const [zeroData, setZeroData] = useState("");

  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchTransactionData = async () => {
      setLoading(true);

      try {
        const resTransactionUser = await API.GetTransactionUser(token, id);

        if (resTransactionUser.data && resTransactionUser.data.length > 0) {
          const sortedData = resTransactionUser.data.sort(
            (a: TransactionUser, b: TransactionUser) => a.id - b.id
          );
          setMetaData(resTransactionUser.meta);
          setUserData(sortedData);
        } else {
          setZeroData(
            resTransactionUser.data.message || "Tidak ada data transaksi."
          );
          setUserData([]);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setZeroData("Gagal memuat data transaksi. Silakan coba lagi.");
      }
      setDataFetched(true);
      setLoading(false);
    };

    if (token && id !== 0) {
      fetchTransactionData();
    } else {
      console.error("No transaction ID found in location state");
      setLoading(false);
    }
  }, [token, id]);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const selectedTransaction = userData.find(
    (transaction) => transaction.id === selectedTransactionId
  );

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  return (
    <>
      {isModalOpen && selectedTransaction && (
        <DetailTransactionUser
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          id={selectedTransactionId}
          data={selectedTransaction}
        />
      )}

      <div className="card mb-xl-8">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Transaksi ({name})
            </span>
          </h3>
        </div>
        <div className="separator separator my-4"></div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
                  <th className="min-w-60px rounded-start text-center">No</th>
                  <th className="min-w-125px text-center">Kode Transaksi</th>
                  <th className="min-w-125px text-center">Tanggal</th>
                  <th className="min-w-125px text-center">Status Pembayaran</th>
                  <th className="min-w-125px text-center">Status Pengiriman</th>
                  <th className="min-w-125px text-center">Total</th>
                  <th className="min-w-60px rounded-end text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {loading && !dataFetched ? (
                  <TransactionSkeleton count={12} />
                ) : userData.length > 0 ? (
                  userData.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex flex-column">
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                          >
                            {transaction.code}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {transaction.shipping.resi
                              ? transaction.shipping.resi
                              : "Nomor resi belum tersedia"}
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <span className="text-dark fw-bold d-block mb-1 fs-6">
                          {formatDate(transaction.date)}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge ${
                            transaction.payment.type === "paid"
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`}
                        >
                          {transaction.payment.type === "paid"
                            ? "Sudah Dibayar"
                            : "Belum Dibayar"}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge ${
                            transaction.status === "pending"
                              ? "badge-light-primary"
                              : transaction.status === "nego"
                              ? "badge-light-warning"
                              : transaction.status === "paid"
                              ? "badge-light-info"
                              : transaction.status === "process"
                              ? "badge-light-warning"
                              : transaction.status === "sent"
                              ? "badge-light-success"
                              : transaction.status === "success"
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`}
                        >
                          {transaction.status === "pending"
                            ? "Pending"
                            : transaction.status === "nego"
                            ? "Negosiasi"
                            : transaction.status === "paid"
                            ? "Diproses"
                            : transaction.status === "process"
                            ? "Dikemas"
                            : transaction.status === "sent"
                            ? "Dikirim"
                            : transaction.status === "success"
                            ? "Selesai"
                            : "Dibatalkan"}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-dark fw-bold d-block mb-1 fs-6">
                          <RupiahFormat value={transaction.grand_total} />
                        </span>
                      </td>
                      <td className="text-center">
                        <Link
                          to={`/marketplace/transaksi/detail/${transaction.id}`}
                          state={{
                            id: transaction.id,
                            data: transaction,
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        >
                          <KTSVG
                            path="/media/icons/duotune/ecommerce/ecm008.svg"
                            className="svg-icon-3"
                          />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center text-muted">
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { TransactionUserList };
