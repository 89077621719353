/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export function Login() {
  const [loading, setLoading] = useState(false);
  const { setCurrentUser, saveAuth } = useAuth();
  const [showAlertError, setShowAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("auth", values.email);
      formData.append("password", values.password);

      const login = await API.Login(formData);

      if (login.status === 200 && login.data.data.role === "admin") {
        setCurrentUser({ token: login.data.data.token });
        saveAuth({ token: login.data.data.token });
      } else {
        setErrorMessage(login.data.message);
        setShowAlertError(true);
      }
    },
  });

  return (
    <div>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Masuk</h1>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            placeholder="Masukkan email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <input
            placeholder="Masukkan password"
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Masuk</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
